p {
  font-size: 22px;
  font-weight: 300;
}

body {
  color: #444444;
  overflow-x: hidden;
}

/* About Page  */
.about-title {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
}

/* Home Page */
.home-center-div {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.skills_image {
  object-fit: contain;
  width: 50%;
  margin-top: 10px;
  background-color: transparent;
}

.accordianFont {
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.social_image {
  object-fit: contain;
  width: 60%;
  padding: "10px";
  margin-top: 10px;
  background-color: transparent;
  border: 10px solid #fff;
}

.form-control {
  margin: 10px 130px;
  padding: 20px;
  border: 10px solid red;
}

.font-style {
  color: #ffff;
}
